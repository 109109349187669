@import './variables';

* {
  box-sizing: border-box;
}

body {
  font-family: Poppins, sans-serif;
  font-weight: 700;
  color: $white;
  background-color: $black;
}

p {
  font-family: Poppins, sans-serif;
  font-size: 1.1rem;
  line-height: 1.7;
  max-width: 100%;
  font-weight: 500;
}

.section-container {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.section-headline {
  font-weight: 600;
}
