// Responsive sizing from Bootstrap
//Bootstrap xs
$xs: '(max-width: 575px)';

//Bootstrap sm
$sm: '(min-width: 576px) and (max-width: 767px)';

//Bootstrap md
$md: '(min-width: 768px) and (max-width: 991px)';

//Bootstrap lg
$lg: '(min-width: 992px) and (max-width: 1200px)';

//Bootstrap xl
$xl: '(min-width: 1200px) and (max-width: 1399px)';

//Bootstrap xxl
$xxl: '(min-width: 1400px)';

// Color scheme
$red: #db545a;
$white: #ffffff;
$gray: #f2f2f2;
$gray-hover: #cecece;
$black: #000000;
$blue: #237acc;
$gradient: linear-gradient(135deg, #2a0634, #462bc9);
