@import '../../variables.scss';

.contact-row {
  margin-top: 0;
}

.display-6 {
  font-size: 1.7rem;
  margin-top: 1.5rem;
}

.copyright {
  font-size: 1.1rem;
  margin: 4rem 0;
  color: rgb(133, 130, 130);
  text-align: center;
}

.socialIcons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
  margin-top: 3rem;
}

.socialIcon {
  width: 50px;
  height: 50px;
  color: $white;

  &:hover {
    cursor: pointer;
    scale: 1.1;
  }
}
