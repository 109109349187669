.scaleOnHover {
  transition: transform 400ms ease-out;

  &:hover {
    transform: scale(1.06);
  }

  &:focus {
    transform: scale(1.06);
  }
}
