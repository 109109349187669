@import '../../variables';

.navbar {
  transition: all 0.5s ease-out;
  background-color: transparent;
  text-transform: capitalize;

  &.scrolled {
    background-color: $white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  .link {
    color: $white;
    font-size: 1.4rem;
    padding: 10px 20px;
    text-decoration: none;

    &:hover {
      color: $red !important;
    }

    &.scrolled {
      color: $black;
      background: $gradient;
      background-clip: border-box;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .collapse {
    justify-content: flex-end;

    @media #{$xs} {
      background-color: $white;
    }

    .link {
      @media #{$xs} {
        color: $black;
        background: $gradient;
        background-clip: border-box;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .brand {
    color: $white;
    font-size: 2rem;

    &:hover {
      color: $red;
    }

    &.scrolled {
      color: $black;
      background: $gradient;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    @media #{$xs} {
      font-size: 1.5rem;
    }
  }
}
