@import '../../variables.scss';

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.65);
}

.modal {
  position: fixed;
  top: 100px;
  left: 10%;
  width: 80%;
  z-index: 100;
  max-height: calc(100% - 120px);
  overflow-y: auto;

  @media #{$md} {
    left: calc(50% - 20rem);
    width: 40rem;
  }
  @media #{$lg} {
    left: calc(50% - 20rem);
    width: 40rem;
  }
  @media #{$xl} {
    left: calc(50% - 20rem);
    width: 40rem;
  }
  @media #{$xxl} {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}

.header {
  background: $gradient;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}

.header h2 {
  margin: 0;
  color: white;
}

.content {
  padding: 1rem;
}

.actions {
  padding: 1rem;
  display: flex;
}

.close {
  font-size: 1.5rem;
  opacity: 1;
}
