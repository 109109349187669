@import '../../variables';

.maxWidthSet {
  @media #{$md} {
    max-width: 306px;
  }
  @media #{$lg} {
    max-width: 306px;
  }
  @media #{$xl} {
    max-width: 306px;
  }
  @media #{$xxl} {
    max-width: 306px;
  }
}
