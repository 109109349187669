@import '../../variables';

.main-text {
  width: 100%;

  @media #{$lg} {
    max-width: 70%;
  }
  @media #{$xl} {
    max-width: 70%;
  }
  @media #{$xxl} {
    max-width: 70%;
  }
}
