@import '../../variables';

.home {
  min-height: 100vh;

  img {
    max-height: 250px;

    @media #{$sm} {
      max-height: 300px;
    }
    @media #{$md} {
      max-height: 400px;
    }
    @media #{$lg} {
      max-height: 400px;
    }
    @media #{$xl} {
      max-height: 400px;
    }
    @media #{$xxl} {
      max-height: 400px;
    }
  }

  .headline {
    font-weight: 800;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media #{$md} {
      align-items: flex-start;
    }
    @media #{$lg} {
      align-items: flex-start;
    }
    @media #{$xl} {
      align-items: flex-start;
    }
    @media #{$xxl} {
      align-items: flex-start;
    }
  }
}
